.cursor .cursor--small,
.cursor .cursor--large,
.cursor .cursor--text {
  position: fixed;
  left: 0;
  z-index: 1111;
  top: 0;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  width: var(--cursor-size);
  height: var(--cursor-size);
  mix-blend-mode: difference;
  pointer-events: none;
  user-select: none;
}
.cursor .cursor--text {
  --cursor-size: fit-content;
  font-size: 0.75rem;
  color: $theme-green;
  opacity: 0;
}
.cursor .cursor--text .text {
  font-family: sans-serif;
  font-weight: bold;
}
.cursor .cursor--small {
  --cursor-size: 10px;
  background: $theme-green;
}
.cursor .cursor--large {
  --cursor-size: 40px;
  border: 2px solid $theme-green;
}